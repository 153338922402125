<script>
import {Message} from "element-ui";
import {RouterLink} from "vue-router";

const xzq = {
   'jinyang': '旌阳区',
   'jingkai': '市本级',
   'luojiang': '罗江区',
   'guanghan': '广汉市',
   'shifang': '什邡市',
   'mianzhu': '绵竹市',
   'zhongjiang': '中江县',
};

const land_purpose = {
   'residential': '住宅',
   'commercial': '商业',
   'industrial': '工业',
}

const transaction_status = {
  'processing': '正在交易',
  'success': '已成交',
  'fail': '未成交',
}
export default {
  name: "infoWindows",
  components: {RouterLink},
  data() {
    return {
      overlay: null,
      infoWindow: null,
      isInfo: true,
    };
  },
  props:{
    mapLandInfo:{
      type: Object,
      default: () => ({
        id: '',
        landPics: "",
        title: "",
        landSize: 99.99,
        regionId: "",
        landPurpose: "",
        amount: "",
        date: "",
        geoInfo:[]
      })
    }
  },
  computed: {
    image() {
      return this.mapLandInfo.landPics ? this.mapLandInfo.landPics.split(",")[0] : "";
    },
  },

  methods: {
    getXzqName(id) {
      return xzq[id];
    },
    getLandPurposeName(id) {
      return land_purpose[id];
    },
    getTransactionStatus(id){
      return transaction_status[id];
    },childMethods(items,landInfo){
      if(items!=null)
      {
        this.fatherMethod(items,landInfo);
      }else {
        Message.error('此地块暂未有地图数据，请关注后续更新');
      }

    }
  },
};
</script>

<template>

  <RouterLink :to="'/land/detail/'+ mapLandInfo.id">
    <div class="popup-box" v-show="mapLandInfo.title">
      <div class="popup">
        <div class="title-box">
          <div class="title">土地名称：</div>
          <div class="text">{{ mapLandInfo.title }}</div>
        </div>
        <div class="title-box">
          <div class="title">面积：</div>
          <div class="text">{{ mapLandInfo.landSize }} 平方米</div>
        </div>
        <div class="title-box">
          <div class="title">所属行政区：</div>
          <div class="text">{{ getXzqName(mapLandInfo.regionId) }}</div>
        </div>
        <div class="title-box">
          <div class="title">土地用途：</div>
          <div class="text">{{ getLandPurposeName(mapLandInfo.landPurpose) }}</div>
        </div>
        <div class="title-box">
           <img style="object-fit:cover" width="280px" height="120px" :src='mapLandInfo.landPics ? mapLandInfo.landPics.split(",")[0] : ""'>
        </div>
      </div>
    </div>
  </RouterLink>


</template>
<style lang="scss" scoped>
.popup-box {
  width: 300px;
  height: 230px;
  border-radius: 8px;
  background: #FFFFFF;
  border: 2px solid #C4C4C499;
  box-shadow: 2px 2px 2px  #3A3A3A26;
  .popup {
    width: 100%;
    height: 100%;
    padding: 14px 0 0 10px;
    .title-box {
      display: flex;
      height: 14px;
      margin-bottom: 8px;
      font-size: 16px;
      color: #141222;
      .title {
        line-height: 18px;
        text-overflow:ellipsis;
        width: 80px;
      }
      .text {
        line-height: 18px;

      }
    }
  }
}
</style>
