<template>

    <div class="land-map-item infinite-list-item">
        <div class="card-left"  @click="childMethods(landInfo.geoInfo,landInfo)" >
            <div :class="'tag ' + landInfo.transactionStatus">
                {{ getTransactionStatus(landInfo.transactionStatus) }}
            </div>
                <img :src="image" class="card-image" />
        </div>
        <div class="card-right"  @click="childMethods(landInfo.geoInfo,landInfo)">
            <!-- <h4><RouterLink to="/tradingServices">{{ landInfo.title }}</RouterLink></h4> -->
            <h4  class="card-info-item">
                {{ landInfo.title }}
            </h4>
            <div class="card-info-item">
                <i class="ti ti-dimensions"></i>
                <span>面积：</span>
                <span>{{ landInfo.landSize }} 平方米</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-map-pin"></i>
                <span>所属行政区：</span>
                <span>{{ getXzqName(landInfo.regionId) }}</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-home-hand"></i>
                <span>土地用途：</span>
               <!-- <span>{{ getLandPurposeName(landInfo.landPurpose) }}</span>-->

              <el-tooltip   :content=" getLandPurposeName(landInfo.landPurpose)+'-'+getLandPurposeItemName(landInfo.landPurpose, landInfo.subLandPurpose)" placement="top" effect="light">
              <span style="overflow: hidden; width: 100px;white-space: nowrap;text-overflow:ellipsis;">
                <el-link>
                {{ getLandPurposeName(landInfo.landPurpose) }}<!-- - {{getLandPurposeItemName(landInfo.landPurpose, landInfo.subLandPurpose)}}-->
                  </el-link>
              </span>
              </el-tooltip>

            </div>

        </div>
    </div>
</template>
<script>
import { Message } from 'element-ui';
import {useDict} from "@/hooks/dict";
const xzq = {
    'jinyang': '旌阳区',
    'jingkai': '市本级',
    'luojiang': '罗江区',
    'guanghan': '广汉市',
    'shifang': '什邡市',
    'mianzhu': '绵竹市',
    'zhongjiang': '中江县',
};

const land_purpose = {
    'residential': '住宅',
    'commercial': '商业',
    'storage': '仓储',
    'synthetical': '综合用地',
    'other': '其他用途',
    'industrial': '工业'
}

const transaction_status = {
    'processing': '正在交易',
    'success': '已成交',
    'fail': '未成交',
}

export default {
    name: "LandMapItem",
    data() {
        return {};
    },
    props: {
      fatherMethod: {
        type: Function,
        default: null
      },
        landInfo: {
            type: Object,
            default: () => ({
                id: '',
                landPics: "",
                title: "name",
                landSize: 99.99,
                regionId: "武侯区",
                landPurpose: "商业用地",
                amount: "100万元",
                date: "2020-01-01 15:00:00",
              geoInfo:[]
            })
        },
    },

    computed: {
        image() {
            return this.landInfo.landPics ? this.landInfo.landPics.split(",")[0]+"?w=150" : "";
        },
    },
    methods: {
        getXzqName(id) {
            return xzq[id];
        },
      getLandPurposeName(id) {
        let land_purposes = useDict('demand_purpose');
        let tmp = '';
        land_purposes.demand_purpose.value.forEach((item) => {
          if (item.value === id) {
            tmp =  item.label;
          }
        })
        return tmp;
      },
      getLandPurposeItemName(demand_purpose,id) {
        let land_purposes = useDict(demand_purpose);
        let tmp = '';
        land_purposes[demand_purpose].value.forEach((item) => {
          if (item.value === id) {
            tmp =  item.label;
          }
        })
        return tmp;
      },
        getTransactionStatus(id){
            return transaction_status[id];
        },childMethods(items,landInfo){
        if(items!=null)
        {
          console.log(items)
          console.log(landInfo)
          this.fatherMethod(items,landInfo);
        }else {
          Message.error('此地块暂未有地图数据，请关注后续更新');
        }

      }
    },
};
</script>
<style lang="scss" scoped></style>
